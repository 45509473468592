import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';
import { addShippingProtectionLink } from '../custom/add-shipping-protection-link';

export const shippingStepLoadingHandler = ({ detail }: EbizioEvent) => {
  logger('Shipping Step Loading');
  console.log(detail);

  addShippingProtectionLink();
};

export const shippingStepLoadingConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.SHIPPING_STEP_LOADING,
  handler: shippingStepLoadingHandler,
};
