export type LoggerSeverity = 'default' | 'error' | 'warning';

export const logger = (
  message = '',
  severity: LoggerSeverity = 'default',
): void => {
  switch (severity) {
    case 'error':
      return console.error(`[Ebizio Events] ${message}`);
    case 'warning':
      return console.warn(`[Ebizio Events] ${message}`);
    default:
      return console.log(`[Ebizio Events] ${message}`);
  }
};
