import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const cartSummaryModalOpenedHandler = (event: EbizioEvent) => {
  logger('Cart Summary Modal Opened');
  console.log(event.detail);
};

export const cartSummaryModalOpenedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.CART_SUMMARY_MODAL_OPENED,
  handler: cartSummaryModalOpenedHandler,
};
