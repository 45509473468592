import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const savedShippingAddressChangedHandler = (event: EbizioEvent) => {
  logger('Saved Shipping Address Changed');
  console.log(event.detail);
};

export const savedShippingAddressChangedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.SAVED_SHIPPING_ADDRESS_CHANGED,
  handler: savedShippingAddressChangedHandler,
};
