import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const customerStepLoadingHandler = (event: EbizioEvent) => {
  logger('Customer Step Loading');
  console.log(event.detail);
};

export const customerStepLoadingConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.CUSTOMER_STEP_LOADING,
  handler: customerStepLoadingHandler,
};
