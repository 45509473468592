import { lang, logger } from '../../utils';

export const addShippingProtectionLink = () => {
  const productFeeToggle = document.querySelectorAll('.productFeeToggle');
  if (productFeeToggle.length) {
    productFeeToggle.forEach((el) => {
      if (el.innerHTML.toLowerCase().includes('platinum')) {
        el.insertAdjacentHTML(
          'afterend',
          `
        <a href="${lang(
          'add_shipping_protection_link.link',
        )}" class="productFeeToggleDescription" target="_blank">${lang(
            'add_shipping_protection_link.text',
          )}</a>
    `,
        );
      }
    });
  }
};
