import { EbizioEventConfig } from './types';
import { CHECKOUT_CONTAINER } from './constants';
import {
  billingStepLoadingConfig,
  cartSummaryModalOpenedConfig,
  checkoutLoadedConfig,
  couponsChangeddConfig,
  customerSignedInConfig,
  customerSignedOutConfig,
  customerStepLoadingConfig,
  giftCertificatesChangedConfig,
  paymentMethodLoadedConfig,
  paymentMethodSelectedConfig,
  paymentStepLoadingConfig,
  savedShippingAddressChangedConfig,
  shippingMethodSelectedConfig,
  shippingStepLoadingConfig,
  storeCreditToggledConfig,
  useNewShippingAddressSelectedConfig,
} from './events';

import './custom/styles';

/**
 * Configuration
 */
export const ebizioEventConfig: EbizioEventConfig[] = [
  // global
  checkoutLoadedConfig,
  couponsChangeddConfig,
  giftCertificatesChangedConfig,
  cartSummaryModalOpenedConfig,
  // customer step
  customerSignedInConfig,
  customerSignedOutConfig,
  customerStepLoadingConfig,
  // shipping step
  shippingStepLoadingConfig,
  shippingMethodSelectedConfig,
  savedShippingAddressChangedConfig,
  useNewShippingAddressSelectedConfig,
  // billing step
  billingStepLoadingConfig,
  // payment step
  paymentStepLoadingConfig,
  paymentMethodSelectedConfig,
  paymentMethodLoadedConfig,
  storeCreditToggledConfig,
];

/**
 * Setup Listeners
 */
ebizioEventConfig.forEach((config) => {
  if (CHECKOUT_CONTAINER) {
    CHECKOUT_CONTAINER.addEventListener(
      config.event,
      config.handler as EventListener,
    );
  }
});
