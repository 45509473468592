import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const storeCreditToggledHandler = ({ detail }: EbizioEvent) => {
  logger('Store Credit Toggled');
  console.log(detail);
};

export const storeCreditToggledConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.STORE_CREDIT_TOGGLED,
  handler: storeCreditToggledHandler,
};
